<template>
  <div>
    <b-form-group
      label="Doküman Tipi"
      label-for="id_com_rental_document_types"
    >
      <validation-provider
        #default="{ errors }"
        name="Doküman Tipi"
        rules="required"
      >
        <v-select
          v-model="dataItem.id_com_rental_document_types"
          :options="rentalDocumentTypes"
          :reduce="item => item.id"
          label="title"
          placeholder="Dosya Tipi"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'RentalDocumentTypes',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    rentalDocumentTypes() {
      return this.$store.getters['rentalDocumentTypes/dataList']
    },
    dataItem() {
      return this.$store.getters['rentalDocuments/dataItem']
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('rentalDocumentTypes/getDataList', {
        select: [
          'com_rental_document_types.id AS id',
          'com_rental_document_types.title AS title',
        ],
        where_in: [
          ['com_rental_document_types.id', [1, 2, 3, 9]],
        ],
      })
    },
  },
}
</script>
