<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Sigorta Talepleri</b-card-title>
      <b-button
        size="sm"
        variant="flat-danger"
        @click="requestModal = true"
      >
        <FeatherIcon icon="PlusIcon" />
        Talep Oluştur
      </b-button>
    </b-card-header>
    <b-table
      v-if="dataList.length > 0"
      class="mt-1"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(insurance_type)="data">
        {{ data.item.insurance_type }}
        <div class="text-muted font-small-2">
          {{ moment(data.item.created).format('ll') }} tarihinde {{ data.item.username }} tarafından oluşturuldu.
        </div>
        <div
          v-if="data.item.notes"
          class="text-danger font-small-2"
        >
          {{ data.item.notes }}
        </div>
      </template>
      <template #cell(id_com_rental_documents)="data">
        <div
          v-if="data.item.id_com_rental_documents"
          class="text-success font-weight-bold"
        >
          Sisteme yüklendi.
        </div>
        <div
          v-else
          class="text-danger font-weight-bold"
        >
          Beklemede
        </div>
      </template>
      <template #cell(filename)="data">
        <b-button
          variant="primary"
          :href="baseURL + data.item.filename"
          target="_blank"
          size="sm"
          :disabled="!data.item.filename"
        >
          <FeatherIcon icon="DownloadIcon" />
          İndir
        </b-button>
        <b-button
          v-if="!data.item.filename"
          class="ml-1"
          variant="flat-danger"
          size="sm"
          @click="removeData(data.item.id)"
        >
          <FeatherIcon icon="TrashIcon" />
          Talep İptal
        </b-button>
      </template>
    </b-table>
    <b-card-body v-else>
      <b-alert show>
        <div class="alert-body text-center">
          Araç kartı için sigorta talebi bulunamadı.
        </div>
      </b-alert>
    </b-card-body>
    <b-modal
      v-model="requestModal"
      hide-footer
      no-close-on-backdrop
      centered
      title="Poliçe Talebi Oluştur"
    >
      <div>
        <b-form-group>
          <v-select
            v-model="insurance.id_com_rental_insurance_types"
            :options="insuranceTypes"
            label="title"
            :reduce="item => item.id"
            placeholder="Poliçe Tipi"
          />
        </b-form-group>
        <b-form-group>
          <b-form-textarea
            v-model="insurance.notes"
            placeholder="Notlar"
          />
        </b-form-group>
        <b-button
          variant="danger"
          size="sm"
          :disabled="!insurance.id_com_rental_insurance_types"
          @click="submitRequest"
        >
          <FeatherIcon icon="CheckIcon" />
          Oluştur
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAlert, BButton, BCard, BCardBody, BCardHeader, BCardTitle, BTable, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Documents',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BButton,
    BAlert,
    BFormGroup,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      requestModal: false,
      baseURL: `${this.$store.state.app.baseURL}/media/rentals/`,
      fields: [
        {
          key: 'insurance_type',
          label: 'Poliçe Talebi',
        },
        {
          key: 'id_com_rental_documents',
          label: 'Durum',
        },
        {
          key: 'filename',
          label: '',
          thClass: 'width-250',
          tdClass: 'text-right text-nowrap',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalInsurances/dataList']
    },
    insurance() {
      return this.$store.getters['rentalInsurances/dataItem']
    },
    insuranceTypes() {
      return this.$store.getters['rentalInsuranceTypes/dataList']
    },
  },
  created() {
    this.getDataList()
    this.getInsuranceTypes()
  },
  methods: {
    getInsuranceTypes() {
      this.$store.dispatch('rentalInsuranceTypes/getDataList', {
        select: [
          'com_rental_insurance_types.id AS id',
          'com_rental_insurance_types.title AS title',
        ],
      })
    },
    getDataList() {
      this.$store.dispatch('rentalInsurances/getDataList', {
        where: {
          'com_rental_insurances.id_com_rental_stocks': this.$route.params.id,
        },
      })
    },
    submitRequest() {
      this.insurance.id_com_rental_stocks = this.$route.params.id
      this.$store.dispatch('rentalInsurances/saveData', this.insurance)
        .then(res => {
          if (res) {
            this.getDataList()
            this.requestModal = false
            this.$store.commit('rentalInsurances/RESET_DATA_ITEM')
          }
        })
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('rentalInsurances/removeData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
  },
}
</script>
