<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Masraflar</b-card-title>
      <div>
        <b-form-checkbox
          v-model="hiddenZero"
          name="check-button"
          switch
          inline
        >
          Sıfır Bakiyeleri Gizle
        </b-form-checkbox>
      </div>
    </b-card-header>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th class="width-50 text-center">
            <FeatherIcon icon="EyeIcon" />
          </b-th>
          <b-th>Masraf Grubu</b-th>
          <b-th class="w-25 text-right">
            Tutar
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(expense,key) in expenses">
          <template v-if="hiddenZero === true">
            <template v-if="expense.expense_total !== 0">
              <summary-row
                :key="key"
                :row-key="key"
                :expense="expense"
              />
              <b-tr
                v-if="expense.detailToggle"
                :key="'detail_' + key"
              >
                <b-td
                  class="p-0"
                  colspan="3"
                >
                  <detail-rows :details="expense.details" />
                </b-td>
              </b-tr>
            </template>
          </template>
          <template v-else>
            <summary-row
              :key="key"
              :row-key="key"
              :expense="expense"
            />
            <b-tr
              v-if="expense.detailToggle"
              :key="'detail_' + key"
            >
              <b-td
                class="p-0"
                colspan="3"
              >
                <detail-rows :details="expense.details" />
              </b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th />
          <b-th>Toplam</b-th>
          <b-th
            class="text-right"
          >
            {{ grandTotal | toCurrency }} ₺
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormCheckbox,
} from 'bootstrap-vue'
import SummaryRow from '@/views/Rental/Stocks/Tabs/Expenses/SummaryRow.vue'
import DetailRows from '@/views/Rental/Stocks/Tabs/Expenses/DetailRows.vue'

export default {
  name: 'Summary',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormCheckbox,
    SummaryRow,
    DetailRows,
  },
  data() {
    return {
      hiddenZero: true,
    }
  },
  computed: {
    expenses() {
      return this.$store.getters['rentalExpenses/dataList']
    },
    grandTotal() {
      return this.$store.getters['rentalExpenses/grandTotal']
    },
  },
}
</script>
