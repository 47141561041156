<template>
  <b-card title="Araç Bilgisi">
    <b-list-group>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Araç Kodu
        </div>
        <div class="font-medium-2">
          {{ dataItem.arackodu }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Tescil Tarihi
        </div>
        <div class="font-medium-2">
          {{ moment(dataItem.registered).format('LL') }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Araç Bilgisi
        </div>
        <div class="font-medium-2">
          {{ dataItem.brand }} {{ dataItem.model }}
        </div>
        <div class="font-medium-2">
          {{ dataItem.title }}
        </div>
        <div class="font-medium-2">
          {{ dataItem.myear }}
        </div>
        <div class="font-medium-2">
          {{ dataItem.fuel }} {{ dataItem.gear }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Şase No
        </div>
        <div class="font-medium-2">
          {{ dataItem.chasis }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.engine">
        <div class="font-weight-bold text-primary">
          Motor No
        </div>
        <div class="font-medium-2">
          {{ dataItem.engine }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Plaka
        </div>
        <div class="font-medium-2">
          {{ dataItem.licence_plate }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Ruhsat Seri No
        </div>
        <div class="font-medium-2">
          {{ dataItem.license_number }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Arvento No
        </div>
        <div class="font-medium-2">
          {{ dataItem.arvento_no? dataItem.arvento_no : '-' }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Renk
        </div>
        <div class="font-medium-2">
          {{ dataItem.color }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col
            cols="auto"
            class="text-center border-right"
          >
            <div class="font-weight-bold text-primary">
              Kabis
            </div>
            <div :class="dataItem.kabis === '1'? 'text-success' : 'text-danger'">
              <FeatherIcon
                :icon="dataItem.kabis === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                size="18"
              />
            </div>
          </b-col>
          <b-col
            cols="auto"
            class="text-center border-right"
          >
            <div class="font-weight-bold text-primary">
              Arvento
            </div>
            <div :class="dataItem.arvento === '1'? 'text-success' : 'text-danger'">
              <FeatherIcon
                :icon="dataItem.arvento === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                size="18"
              />
            </div>
          </b-col>
          <b-col
            cols="auto"
            class="text-center border-right"
          >
            <div class="font-weight-bold text-primary">
              Kredi
            </div>
            <div :class="dataItem.kredi === '1'? 'text-success' : 'text-danger'">
              <FeatherIcon
                :icon="dataItem.kredi === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                size="18"
              />
            </div>
          </b-col>
          <b-col
            cols="auto"
            class="text-center border-right"
          >
            <div class="font-weight-bold text-primary">
              Amortisman
            </div>
            <div :class="dataItem.amortisman === '1'? 'text-success' : 'text-danger'">
              <FeatherIcon
                :icon="dataItem.amortisman === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                size="18"
              />
            </div>
          </b-col>
          <b-col
            cols="auto"
            class="text-center"
          >
            <div class="font-weight-bold text-primary">
              Taşıtmatik
            </div>
            <div :class="dataItem.tasitmatik === '1'? 'text-success' : 'text-danger'">
              <FeatherIcon
                :icon="dataItem.tasitmatik === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                size="18"
              />
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item :variant="dataItem.status_variant">
        <div class="font-weight-bold">
          Durum
        </div>
        <div class="font-medium-2">
          {{ dataItem.stock_status }}
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'ItemView',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('rentalStocks/getDataItem', this.$route.params.id)
        .then(res => {
          if (res.arackodu) {
            this.$store.dispatch('rentalExpenses/getDataList', res.arackodu)
          }
        })
    },
  },
}
</script>
