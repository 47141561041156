<template>
  <div>
    <b-form-group
      label="Dosya"
      label-for="upload_file"
    >
      <validation-provider
        #default="{ errors }"
        name="Dosya"
        rules="required"
      >
        <b-form-file
          v-model="dataItem.upload_file"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosya buraya bırakın..."
          browse-text="Dosya Seçin"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'UploadFile',
  components: {
    BFormGroup,
    BFormFile,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalDocuments/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
