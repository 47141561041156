<template>
  <b-table-simple
    fixed
    small
  >
    <b-thead>
      <b-tr class="bg-warning">
        <b-th class="bg-transparent text-light">
          Fiş No
        </b-th>
        <b-th class="bg-transparent text-light text-center">
          Tarih
        </b-th>
        <b-th class="bg-transparent text-light text-right">
          2. El Satış Tarihi
        </b-th>
        <b-th class="bg-transparent text-light text-right">
          Bakiye
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="(detail,key) in details"
        :key="key"
        class="bg-light-primary"
      >
        <b-td>
          {{ detail.fis_no }}
          <div class="font-small-2">
            {{ detail.aciklama }}
          </div>
        </b-td>
        <b-td class="text-center">
          {{ moment(detail.edate).format('DD.MM.YYYY') }}
        </b-td>
        <b-td class="text-right">
          {{ detail.g2_satis_tarihi? moment(detail.g2_satis_tarihi).format('DD.MM.YYYY') : '-' }}
        </b-td>
        <b-td class="text-right">
          {{ detail.bakiye | toCurrency }} ₺
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  name: 'DetailRows',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
}
</script>
