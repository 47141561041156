<template>
  <b-tabs pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Araç Bilgisi</span>
      </template>
      <item-view />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" />
        <span>Sözleşmeler</span>
      </template>
      <contracts />
    </b-tab>
    <b-tab v-if="['12','13','15'].includes(userData.id_com_user_type)">
      <template #title>
        <feather-icon icon="BookIcon" />
        <span>Masraflar</span>
      </template>
      <expenses />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ArchiveIcon" />
        <span>Dokümanlar</span>
      </template>
      <documents />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="UmbrellaIcon" />
        <span>Sigorta Talepleri</span>
      </template>
      <insurances />
    </b-tab>
  </b-tabs>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ItemView from '@/views/Rental/Stocks/ItemView.vue'
import Contracts from '@/views/Rental/Stocks/Tabs/Contracts.vue'
import Expenses from '@/views/Rental/Stocks/Tabs/Expenses.vue'
import Documents from '@/views/Rental/Stocks/Tabs/Documents.vue'
import Insurances from '@/views/Rental/Stocks/Tabs/Insurances.vue'

export default {
  name: 'RentalView',
  components: {
    BTabs,
    BTab,
    ItemView,
    Contracts,
    Expenses,
    Documents,
    Insurances,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
  },
  created() {
    this.$store.commit('rentalStocks/RESET_DATA_ITEM')
  },
}
</script>
