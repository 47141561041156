<template>
  <b-tr>
    <b-td class="text-center">
      <b-button
        :variant="!expense.detailToggle? 'flat-success' : 'flat-danger'"
        pill
        class="btn-icon"
        @click="setDetailToggle"
      >
        <FeatherIcon
          :icon="!expense.detailToggle? 'EyeIcon' : 'EyeOffIcon'"
        />
      </b-button>
    </b-td>
    <b-td>
      {{ expense.expense_group }}
    </b-td>
    <b-td class="text-right">
      {{ expense.expense_total | toCurrency }} ₺
    </b-td>
  </b-tr>
</template>
<script>
import { BTr, BTd, BButton } from 'bootstrap-vue'

export default {
  name: 'SummaryRow',
  components: {
    BTr,
    BTd,
    BButton,
  },
  props: {
    expense: {
      type: Object,
      required: true,
    },
    rowKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    expenses() {
      return this.$store.getters['rentalExpenses/dataList']
    },
  },
  methods: {
    setDetailToggle() {
      this.expenses[this.rowKey].detailToggle = !this.expenses[this.rowKey].detailToggle
    },
  },
}
</script>
