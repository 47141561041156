<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Sözleşmeler</b-card-title>
    </b-card-header>
    <b-table
      v-if="dataList.length > 0"
      class="mt-1"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(customer_name)="data">
        <div v-if="data.item.company_name">
          {{ data.item.company_name }}
          <div class="text-muted font-small-2">
            {{ data.item.customer_name }}
          </div>
        </div>
        <div v-else>
          {{ data.item.customer_name }}
        </div>
      </template>
      <template #cell(sdate)="data">
        {{ moment(data.item.sdate).format('DD.MM.YYYY') }}
      </template>
      <template #cell(edate)="data">
        {{ moment(data.item.edate).format('DD.MM.YYYY') }}
        <div
          v-if="data.item.id_com_rental_contract_status === '1'"
          class="font-small-2 text-warning"
        >
          Kalan {{ moment(data.item.edate).diff(moment(),'days') }} Gün
        </div>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="data.item.id_com_rental_contract_status === '1'? 'success' : 'danger'">
          {{ data.item.contract_status }}
        </b-badge>
      </template>
    </b-table>
    <b-card-body v-else>
      <b-alert show>
        <div class="alert-body text-center">
          Araç kartı için sözleşme bulunamadı.
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BTable, BBadge, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'Contracts',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BBadge,
    BAlert,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer_name',
          label: 'MÜŞTERİ',
        },
        {
          key: 'sdate',
          label: 'BAŞLANGIÇ',
        },
        {
          key: 'edate',
          label: 'BİTİŞ',
        },
        {
          key: 'status',
          label: 'DURUM',
          thStyle: { width: '220px' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalContracts/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('rentalContracts/getDataList', {
        where: {
          'com_rental_contracts.id_com_rental_stocks': this.$route.params.id,
        },
      })
    },
  },
}
</script>
