<template>
  <validation-observer ref="simpleRules">
    <rental-document-types />
    <b-row v-if="['1','2'].includes(dataItem.id_com_rental_document_types)">
      <b-col>
        <start-date />
      </b-col>
      <b-col>
        <end-date />
      </b-col>
    </b-row>
    <upload-file />
    <pre-loading
      v-if="uploading"
      class="my-2"
    />
    <save-button
      v-else
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </validation-observer>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import RentalDocumentTypes from '@/views/Rental/Stocks/Tabs/Documents/elements/DocumentTypes.vue'
import StartDate from '@/views/Rental/Stocks/Tabs/Documents/elements/StartDate.vue'
import EndDate from '@/views/Rental/Stocks/Tabs/Documents/elements/EndDate.vue'
import UploadFile from '@/views/Rental/Stocks/Tabs/Documents/elements/UploadFile.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'UploadForm',
  components: {
    BRow,
    BCol,
    RentalDocumentTypes,
    StartDate,
    EndDate,
    UploadFile,
    SaveButton,
    ValidationObserver,
    PreLoading,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      submitStatus: false,
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalDocuments/dataItem']
    },
    saveStatus() {
      return this.$store.getters['rentalDocuments/dataSaveStatus']
    },
    uploading() {
      return this.$store.getters['rentalDocuments/uploading']
    },
  },
  created() {
    localize('tr')
    this.$store.commit('rentalDocuments/RESET_DATA_ITEM')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.id_com_rental_stocks = this.$route.params.id
          this.$store.dispatch('rentalDocuments/saveData', this.dataItem)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
        }
      })
    },
  },
}
</script>
