<template>
  <div>
    <table-expense />
  </div>
</template>

<script>
import TableExpense from '@/views/Rental/Stocks/Tabs/Expenses/TableExpense.vue'

export default {
  name: 'Expenses',
  components: {
    TableExpense,
  },
}
</script>
